@import "../../../assets/scss/variables";
@import "../../../assets/scss/mixins";
@import "../../../assets/scss/function";

.Store {
  background-color: #0a0a0a;
  height: 80vh;
  min-height: 600px;
  width: 100%;
  overflow: hidden;
  position: relative;
  display: flex;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  justify-content: center;
  align-items: center;
  padding: 64px 20px;
  box-sizing: border-box;
  z-index: 2;

  .bg {
    position: absolute;
    height: 100%;
    left: 0;
    width: 100%;
    min-height: 600px;
    background-color: black;
    opacity: 40%;
    z-index: 5;
  }

  @include breakpoint-for(tablet) {
    justify-content: flex-start;
    padding: 94px;
  }

  .spot {
    background-color: #ff0000d6;
    margin: 0;
    font-family: $bebasneue_light;
    font-size: pxToRem(48px);
    color: $white;
    right: 0;
    top: 0;
    width: 100%;
    overflow: hidden;
    z-index: 2;
    white-space: nowrap;
    margin: 20px 0;

    @include breakpoint-for(desktop-small) {
      font-size: pxToRem(48px);
      left: initial;
    }

    span {
      font-family: $bebasneue_normal;
    }
  }

  .background-section {
    bottom: 4%;
    height: 550px;
    left: 46%;
    position: absolute;
    z-index: 1;

    @include breakpoint-for(desktop-extra-large) {
      height: auto;
      left: 53%;
    }

    @include breakpoint-for("4k") {
      display: none;
    }
  }

  .container-info {
    color: $white;
    margin-top: 88px;
    z-index: 6;
    
    @include breakpoint-for(tablet) {
      margin: 0;
    }

    span {
      font-family: $bebasneue_normal;
      font-size: pxToRem(48px);
    }

    .info-store {
      margin-top: 22px;
      background-color: rgba(49, 49, 49, 1);
      // padding: 48px;
      padding: 48px 0;
      box-sizing: border-box;
      max-width: 462px;

      .describe {
        font-family: $gotham_light;
        font-size: pxToRem(16px);
        text-align: left;
        margin-top: 0;
        margin-bottom: 36px;
        padding: 0 48px;
        width: 347px;
      }

      .ButtonContainer {
        width: 347px;
        padding: 0 48px;

        a {
          width: 100%;

          .Button {
            width: 100%;
            font-size: pxToRem(24px);
          }
        }
      }
    }
  }
}
