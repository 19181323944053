@import "../../../../assets/scss/variables";
@import "../../../../assets/scss/mixins";
@import "../../../../assets/scss/function";

.slider {
  display: flex;
  justify-content: center;
  width: 100%;

  @include breakpoint-for(desktop-medium) {
    align-items: center;
  }
}

.slider-images {
  display: flex;
  overflow-x: hidden;
  align-items: flex-start;
  justify-content: center;
  width: 100%;

  @include breakpoint-for(desktop-medium) {
    align-items: center;
    justify-content: center;
  }
}

.slider-images div {
  max-width: 100%;
  opacity: 0;
  transition: opacity 0.5s ease;
  position: absolute;
  background-size: cover;
  background-position: center;
  border-radius: 4px;
  height: 300px;
  width: 90%;

  @include breakpoint-for(desktop-small) {
    height: 500px;
  }

  @include breakpoint-for(desktop-medium) {
    height: 600px;
    width: 75%;
  }
}

.slider-images div.active {
  opacity: 1;
  height: 300px;
  width: 90%;

  @include breakpoint-for(desktop-small) {
    min-height: 500px;
  }

  @include breakpoint-for(desktop-medium) {
    width: 75%;
    height: 600px !important;
  }
}

.prev-mobile,
.next-mobile {
  display: flex;
  height: 30px;
  position: relative;
  z-index: 1;
  top: 105%;
  background-color: $secondary_color;
  color: $black;
  font-family: $gotham_normal;
  align-items: center;
  border-radius: 4px;
  border: 1px solid rgba(0, 0, 0, 0.193);
  border-radius: 50%;

  @include breakpoint-for(tablet) {
    top: 105%;
  }

  @include breakpoint-for(desktop-small) {
    top: 85%;
  }

  @include breakpoint-for(desktop-medium) {
    display: none;
  }
}

.prev,
.next {
  cursor: pointer;
  position: relative;
  z-index: 1;
  transform: translateY(-50%);
  display: none;

  img {
    border-radius: 4px;
    height: 200px;
    width: 200px;
  }

  @include breakpoint-for(desktop-medium) {
    transform: unset;
    display: flex;

    img {
      height: 300px;
      width: 300px;
      border-radius: 4px;
    }
  }
}

.prev-mobile {
  left: 35%;
}

.next-mobile {
  right: 35%;

  img {
    rotate: 180deg;
  }
}

.prev {
  left: 10px;

  @include breakpoint-for(desktop-medium) {
    left: -150px;
  }
}

.next {
  right: 10px;
  @include breakpoint-for(desktop-medium) {
    right: -150px;
  }
}
