@import "../../../../assets/scss/variables";
@import "../../../../assets/scss/mixins";
@import "../../../../assets/scss/function";

.popup-background {
  display: flex;
  align-items: center;
  justify-content: center;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 20;

  .popup-content {
    background-color: white;
    padding: 30px 40px;
    box-sizing: border-box;
    border-radius: 4px;
    max-width: 90%;
    max-height: 90%;
    overflow: auto;
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;

    @include breakpoint-for(tablet) {
      max-width: 60%;
    }

    span {
      color: red;
      cursor: pointer;
      display: flex;
      font-size: 50px;
      position: absolute;
      right: 20px;
      top: 0px;
    }

    h2 {
      font-family: $bebasneue_light;
      font-size: pxToRem(56px);
      line-height: 73px;
      margin: 0;
    }

    p {
      font-family: $gotham_normal;
      font-size: pxToRem(20px);
      text-align: left;
      // margin: 40px 0 0 0;
      display: flex;
      justify-content: center;
      flex-direction: column;
      align-items: center;

      @include breakpoint-for(tablet) {
        width: 80%;
      }
    }

    .close-btn {
      position: absolute;
      top: 10px;
      right: 10px;
      cursor: pointer;
    }
  }
}
