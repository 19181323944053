@import "../../../assets/scss/variables";
@import "../../../assets/scss/mixins";
@import "../../../assets/scss/function";

.Navbar {
  align-items: center;
  background-color: $black;
  top: 0;
  box-sizing: border-box;
  display: flex;
  gap: 0 20px;
  height: 100px;
  justify-content: space-between;
  padding: 0 10px 0 10px;
  border-bottom: 1px solid $secondary_color;
  position: fixed;
  width: 100%;
  z-index: 10;

  @include breakpoint-for(desktop-medium) {
    padding: 0 20px 0 20px;
  }

  @include breakpoint-for(desktop-large) {
    padding: 0 10% 0 5%;
  }

  img {
    height: 100%;
    z-index: 10;

    @include breakpoint-for(tablet) {
      width: 300px;
    }
  }

  h1 {
    color: $secondary_color;
    font-family: $bebasneue_light;
    font-weight: 100;
    margin: 0;
    display: none;
  }

  .container-navbar {
    display: none;
    gap: 48px;
    justify-content: flex-end;
  }

  .hamburger {
    background-color: transparent;
    border: none;
    cursor: pointer;
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    width: 40px;
    height: 30px;
    z-index: 11;
  }

  .line {
    width: 100%;
    height: 3px;
    border-radius: 6px;
    background-color: $secondary_color;
  }

  .modal {
    position: fixed;
    top: 120px;
    left: 0;
    width: 100%;
    // height: 173px;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 10;
  }

  .modal-content {
    background-color: $black;
    padding: 20px;
    height: 100%;
    flex-direction: column;
    display: flex;
    gap: 30px;
    width: 100%;
    align-items: flex-start;
    border-radius: 5px;
  }

  @include breakpoint-for(desktop-small) {
    h1 {
      display: flex;
    }

    .container-navbar {
      display: flex;
    }

    .hamburger {
      display: none;
    }
  }
}
