@import "../../../assets/scss/variables";
@import "../../../assets/scss/mixins";
@import "../../../assets/scss/function";

.NavigationLink {
  color: white;
  font-family: $gotham_light;
  font-size: pxToRem(15px);
  height: 20px;
  margin: 0;
  padding: 0;
  text-decoration: none;
  text-transform: uppercase;

  @include breakpoint-for(tablet-large) {
    font-family: $gotham_light;
    font-size: pxToRem(15px);
    color: white;
    text-transform: unset;
  }

  @include breakpoint-for(desktop-medium) {
    font-size: pxToRem(15px);
  }

  &.active,
  &:hover {
    color: $secondary_color;
    font-family: $gotham_normal;
  }
}
