@import "../../../assets/scss/variables";
@import "../../../assets/scss/mixins";
@import "../../../assets/scss/function";

.Space {
  background-color: rgba(217, 217, 217, 1);
  display: flex;
  overflow: hidden;
  width: 100%;
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
  padding: 86px 0;

  .title {
    font-family: $bebasneue_normal;
    font-size: pxToRem(40px);
    margin: 0 0 48px 0;

    @include breakpoint-for(tablet) {
      font-size: pxToRem(48px);
    }
  }

  .container-slide {
    display: flex;
    min-height: 300px;
    width: 100%;

    @include breakpoint-for(desktop-small) {
      min-height: 500px;
    }

    @include breakpoint-for(desktop-small) {
      min-height: 600px;
    }
  }

  .info-space {
    background-color: $white;
    padding: 24px;
    box-sizing: border-box;
    display: flex;
    border-radius: 4px;
    align-items: center;
    gap: 34px;
    margin: 100px 20px 0;
    flex-direction: column;

    @include breakpoint-for(desktop-small) {
      flex-direction: row;
      margin: 50px 0 0 0;
    }

    div {
      display: flex;
      align-items: center;
      justify-content: center;
      flex-direction: column;

      @include breakpoint-for(desktop-small) {
        flex-direction: row;
      }

      p {
        font-family: $gotham_light;
        font-size: pxToRem(16px);
        margin: 29px 0 0 0;
        text-align: left;

        @include breakpoint-for(desktop-small) {
          margin: 0 0 0 29px;
          flex-direction: row;
        }
      }
    }

    .Button {
      padding: 13px 24px;
      font-family: $bebasneue_normal;
      font-size: pxToRem(24px);
      color: rgba(0, 91, 88, 1);
      width: max-content;
    }
  }
}
