@import "../../../../assets/scss/variables";
@import "../../../../assets/scss/mixins";
@import "../../../../assets/scss/function";

.popup-background {
  align-items: center;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  height: 100%;
  justify-content: center;
  left: 0;
  position: fixed;
  top: 0;
  width: 100%;
  z-index: 20;

  .popup-content-teacher {
    background-color: white;
    border-radius: 4px;
    box-sizing: border-box;
    max-height: 90%;
    max-width: 90%;
    overflow: auto;
    padding: 30px 20px;
    position: relative;

    @include breakpoint-for(tablet) {
      padding: 30px 60px;
      max-width: 60%;
    }

    .teacher {
      max-width: 755px;
      width: 100%;
    }

    .names {
      display: flex;
    }

    span {
      color: red;
      cursor: pointer;
      display: flex;
      font-size: 50px;
      position: absolute;
      right: 20px;
      top: 0px;
    }

    h2 {
      font-family: $bebasneue_light;
      font-size: pxToRem(56px);
      line-height: 73px;
      margin: 0;
      padding: 30px;
    }

    p {
      font-family: $gotham_normal;
      font-size: pxToRem(20px);
      margin: 20px 0 0 0;
      text-align: justify;
    }

    img {
      border-radius: 4px;
      max-height: 500px;
      max-width: fit-content;
      max-width: -webkit-fit-content;
      width: 100%;
      
      &.Paula {
        width: 350px;
      }
    }

    .close-btn {
      cursor: pointer;
      position: absolute;
      right: 10px;
      top: 10px;
    }
  }
}
