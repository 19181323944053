@import "../../../assets/scss/variables";
@import "../../../assets/scss/mixins";
@import "../../../assets/scss/function";

.ClientLogoCarousel {
  width: 100%;
  margin: auto;
  background-color: black;
  height: 100px;
  padding: 30px 0 90px;
  border-bottom: 1px solid rgba(255, 255, 255, 0.21);

  .title {
    color: white;
    font-family: $gotham_light;
    font-size: pxToRem(30px);
    line-height: 20px;
    text-align: center;
    margin: 0 0 50px 0;
  }

  .slick-slide {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100px;

    a {
      display: flex;
      justify-content: center;
      align-items: center;

      img {
        height: 60px;
        max-width: 100px;
        width: 100%;
      }
    }
  }
}
