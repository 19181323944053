.whatsapp-float {
  position: fixed;
  width: 60px;
  height: 60px;
  bottom: 40px;
  right: 10px;
  background-color: #25d366;
  color: #fff;
  border-radius: 50px;
  text-align: center;
  box-shadow: 2px 2px 3px #9999996b;
  z-index: 1000;

  img {
    width: 50px;
    height: 50px;
    margin-top: 5px;
  }
}
