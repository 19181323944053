.custom-responsive-masonry {
  display: flex;
  flex-wrap: wrap;
  gap: 20px;
}

.custom-responsive-masonry-column {
  flex: 1 1 0;
}

.custom-responsive-masonry-item {
  width: 100%;
  margin: 20px;

  img {
    border-radius: 10px;
  }
}
