@import "../../../assets/scss/variables";
@import "../../../assets/scss/mixins";
@import "../../../assets/scss/function";

.Footer {

  #map-frame {
    width: 100%;
    height: 400px;
  }

  .map {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  footer {
    display: flex;
    justify-content: space-between;
    background-color: $black;
    padding: 61px 20px;
    box-sizing: border-box;
    flex-direction: column;

    @include breakpoint-for(desktop-small) {
      flex-direction: row;
      padding: 61px 177px 61px 46px;
    }

    .left-footer {
      .top-left {
        display: flex;
        align-items: center;
        gap: 40px;
        flex-direction: column;

        @include breakpoint-for(desktop-small) {
          gap: 120px;
          flex-direction: row;
        }

        div {
          gap: 16px;
          display: flex;

          a {
            cursor: pointer;
          }
        }
      }

      .bottom-left {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        padding: 24px;
        gap: 17px;
        margin-top: 30px;
        flex-direction: column;

        @include breakpoint-for(desktop-small) {
          margin-top: 60px;
        }

        div {
          gap: 15px;
          display: flex;

          a {
            color: $white;
            font-family: $gotham_light;
            font-size: pxToRem(16px);
            outline: none;
            text-align: left;
            text-decoration: unset;
          }
        }
      }
    }

    .right-footer {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      padding: 24px;

      @include breakpoint-for(desktop-small) {
        align-items: flex-start;
      }

      p {
        margin: 5px 0 20px;
        font-family: $gotham_light;
        font-size: pxToRem(16px);
        color: $secondary_color;

        @include breakpoint-for(desktop-small) {
          margin: 40px 0 20px;
        }
      }

      div {
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        align-items: flex-start;
        gap: 15px;

        @include breakpoint-for(desktop-small) {
          flex-wrap: nowrap;
          flex-direction: column;
        }

        a {
          color: $white;
          outline: none;
          text-decoration: unset;
          font-family: $gotham_light;
          font-size: pxToRem(16px);

          &:hover {
            color: $secondary_color;
          }
        }
      }
    }
  }
}
