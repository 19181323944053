@import "../../../assets/scss/variables";
@import "../../../assets/scss/mixins";
@import "../../../assets/scss/function";

.Biography {
  background-color: rgba(232, 232, 232, 1);
  width: 100%;
  display: flex;
  align-items: center;
  padding: 60px 20px;
  box-sizing: border-box;
  gap: 0 100px;
  flex-direction: column;
  box-sizing: border-box;
  overflow: hidden;

  @include breakpoint-for(desktop-medium) {
    flex-direction: row;
    padding: 120px 60px;
    min-height: 100vh;
  }

  .container-photos {
    // margin: 150px 0 100px;
    // margin-bottom: 250px;
    position: relative;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    // height: 100%;
    max-height: 100vh;
    // width: 40%;
    flex-wrap: wrap;
    // min-height: 500px;
    // height: 360px;
    height: 257px;

    @include breakpoint-for(tablet) {
      height: 360px;
    }

    @include breakpoint-for(desktop-medium) {
      width: 100%;
      // width: 3000px;
      height: 800px;
    }

    .photo {
      width: 100%;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: space-between;
      background-color: white;
      height: 150px;
      width: 100px;
      padding: 5px 5px 0 5px;
      box-sizing: border-box;
      z-index: 2;
      // position: relative;
      position: absolute;

      &.one {
        left: 56%;
        top: -15%;
        rotate: -9deg;
      }

      &.two {
        left: 74%;
        top: -15%;
        rotate: -25deg;
      }

      &.three {
        // right: 0%;
        left: 80%;
        // top: -20%;
        bottom: 0%;
        rotate: 20deg;
      }

      &.four {
        // right: 20%;
        left: 65%;
        // top: -20%;
        bottom: 0%;
        rotate: -10deg;
      }

      &.five {
        rotate: -20deg;
        left: 39%;
        top: -15%;
      }

      &.six {
        left: 48%;
        bottom: 0%;
        rotate: -5deg;
      }

      &.seven {
        left: 32%;
        bottom: 0%;
        rotate: 23deg;
      }

      &.eight {
        left: 0px;
        top: -15%;
        rotate: 10deg;
      }

      &.nine {
        // right: 10px;
        left: 15%;
        // top: -20%;
        bottom: 0%;
        // rotate: 20deg;
      }

      &.ten {
        left: 0%;
        // top: 40px;
        bottom: 0%;
        rotate: -20deg;
      }

      &.eleven {
        left: 20%;
        rotate: -28deg;
        top: -15%;
      }

      &.twelve {
        bottom: unset;
        left: -10%;
        rotate: -20deg;
        rotate: -28deg;
        top: -10%;
      }

      &.thirteen {
        bottom: unset;
        left: -10%;
        rotate: 10deg;
        top: 45%;
      }

      @include breakpoint-for(tablet) {
        height: 200px;
        width: 150px;
        padding: 10px 10px 0 10px;
        
        &.one {
          left: 56%;
          top: -15%;
          rotate: -9deg;
        }

        &.two {
          left: 74%;
          top: -18%;
          rotate: -25deg;
        }

        &.three {
          // right: 0%;
          left: 80%;
          // top: -20%;
          bottom: 0%;
          rotate: 20deg;
        }

        &.four {
          // right: 20%;
          left: 65%;
          // top: -20%;
          bottom: 0%;
          rotate: -10deg;
        }

        &.five {
          rotate: -20deg;
          left: 39%;
          top: -15%;
        }

        &.six {
          left: 48%;
          bottom: 0%;
          rotate: -5deg;
        }

        &.seven {
          left: 32%;
          bottom: 0%;
          rotate: 23deg;
        }

        &.eight {
          left: 0px;
          top: -15%;
          rotate: 10deg;
        }

        &.nine {
          // right: 10px;
          left: 15%;
          // top: -20%;
          bottom: 0%;
          // rotate: 20deg;
        }

        &.ten {
          left: 0%;
          // top: 40px;
          bottom: 0%;
          rotate: -20deg;
        }

        &.eleven {
          left: 20%;
          rotate: -28deg;
          top: -15%;
        }

        &.twelve {
          bottom: unset;
          left: 10%;
          rotate: -20deg;
          rotate: -28deg;
          top: 15%;
        }

        &.thirteen {
          bottom: unset;
          left: 10%;
          rotate: 10deg;
          top: 45%;
        }
      }

      @include breakpoint-for(desktop-small) {
        height: 250px;
        width: 200px;
        padding: 20px 20px 0 20px;
      }

      @include breakpoint-for(desktop-medium) {
        height: 300px;
        width: 250px;

        &.one {
          left: 70%;
          top: -10%;
          rotate: -9deg;
        }

        &.two {
          left: 75%;
          top: 15%;
          rotate: 5deg;
        }

        &.three {
          // right: 0%;
          left: 70%;
          top: 45%;
          bottom: unset;
          rotate: 20deg;
        }

        &.four {
          // right: 0%;
          left: 76%;
          top: 75%;
          bottom: unset;
          rotate: 10deg;
        }

        &.five {
          rotate: -20deg;
          left: 30%;
          top: 75%;
          bottom: unset;
        }

        &.six {
          rotate: -5deg;
          left: 30%;
          top: -10%;
          bottom: unset;
        }

        &.seven {
          rotate: 0deg;
          left: 34%;
          top: 20%;
          bottom: unset;
        }

        &.eight {
          left: 30%;
          top: 50%;
          bottom: unset;
          rotate: 10deg;
        }

        &.nine {
          left: 35%;
          top: 70%;
          bottom: unset;
          rotate: 10deg;
        }

        &.ten {
          bottom: unset;
          left: -10%;
          rotate: -20deg;
          top: 70%;
        }

        &.eleven {
          bottom: unset;
          left: -10%;
          rotate: -20deg;
          rotate: -28deg;
          top: -10%;
        }

        &.twelve {
          bottom: unset;
          left: -10%;
          rotate: -20deg;
          rotate: -28deg;
          top: 15%;
        }

        &.thirteen {
          bottom: unset;
          left: -10%;
          rotate: 10deg;
          top: 45%;
        }
      }

      .name {
        display: flex;
        align-items: flex-start;
        height: 20%;

        p {
          font-family: "Architects Daughter";
          font-size: 10px;
          margin: 0;
          color: white;
          visibility: hidden;

          @include breakpoint-for(tablet) {
            font-size: 12px;
          }
        }
      }

      .photo-url {
        width: 90%;
        height: 70%;
        background-size: cover;
        background-position: 82% 15%;
      }

    }

    .photo {
      // opacity: 0.6;
      transition: opacity 0.3s ease;
    }

    .photo.principal {
      opacity: 1;
      z-index: 5;
      // position: absolute;
      rotate: unset;
    }
  }

  img {
    width: 100%;
    max-width: 498.32px;

    @include breakpoint-for(desktop-medium) {
      width: 30%;
    }
  }

  .container-biography {
    border-radius: 4px;
    background-color: $white;
    padding: 20px;
    box-sizing: border-box;
    min-height: 686px;
    position: relative;
    z-index: 2;
    margin-top: 40px;

     @include breakpoint-for(tablet) {
      margin: 0;
    }

    @include breakpoint-for(desktop-medium) {
      padding: 80px;
      max-width: 1091px;
    }

    p {
      font-family: $architects_normal;
      font-size: pxToRem(26px);
      color: $black;
      text-align: left;
      margin-top: 0;
    }

    .assinature {
      position: absolute;
      font-family: $architects_normal;
      font-size: pxToRem(24px);
      right: 8%;
      bottom: -10px;
      rotate: -15deg;
      display: none;

      @include breakpoint-for(tablet) {
        display: flex;
        bottom: -10px;
      }
    }
  }
}
