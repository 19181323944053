@mixin font-face(
  $font-family,
  $font-weight,
  $font-style,
  $font-display,
  $local,
  $otf-suffix
) {
  @font-face {
    font-family: $font-family;
    font-weight: $font-weight;
    font-style: $font-style;
    font-display: $font-display;
    unicode-range: U+000-5FF;
    src: local($local), url($otf-suffix) format("opentype");
  }
}

@include font-face(
  "Bebas Neue Normal",
  500,
  normal,
  swap,
  "Bebas Neue Normal",
  "../fonts/BebasNeueRegular.otf"
);

@include font-face(
  "Bebas Neue Light",
  300,
  normal,
  swap,
  "Bebas Neue Light",
  "../fonts/BebasNeueLight.otf"
);

@include font-face(
  "Bebas Neue Thin",
  100,
  normal,
  swap,
  "Bebas Neue Thin",
  "../fonts/BebasNeueThin.otf"
);

@include font-face(
  "Bebas Neue Book",
  400,
  normal,
  swap,
  "Bebas Neue Book",
  "../fonts/BebasNeueBook.otf"
);

@include font-face(
  "Bebas Neue Bold",
  700,
  normal,
  swap,
  "Bebas Neue Bold",
  "../fonts/BebasNeueBold.otf"
);

@include font-face(
  "Gotham Light",
  300,
  normal,
  swap,
  "Gotham Light",
  "../fonts/Gotham-Light.otf"
);

@include font-face(
  "Gotham Normal",
  400,
  normal,
  swap,
  "Gotham Normal",
  "../fonts/Gotham-Book.otf"
);

@include font-face(
  "Gotham Medium",
  500,
  normal,
  swap,
  "Gotham Medium",
  "../fonts/Gotham-Medium.otf"
);

@include font-face(
  "Gotham Thin",
  100,
  normal,
  swap,
  "Gotham Thin",
  "../fonts/Gotham-Thin.otf"
);

@include font-face(
  "Gotham Bold",
  700,
  normal,
  swap,
  "Gotham Bold",
  "../fonts/Gotham-Bold.otf"
);

@include font-face(
  "Architects Daughter",
  400,
  normal,
  swap,
  "Architects Daughter",
  "../fonts/ArchitectsDaughter-Regular.otf"
);

