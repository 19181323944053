@import "../../../assets/scss/variables";
@import "../../../assets/scss/mixins";
@import "../../../assets/scss/function";

.Records {
  align-items: center;
  background-image: url("../../../assets/images/records/bg-records.jpeg");
  background-position: center;
  background-size: cover;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  height: 100vh;
  padding: 10px;
  position: relative;

  @include breakpoint-for(desktop-small) {
    padding: 10px 30px;
  }

  &::before {
    content: "";
    background-color: rgba(0, 0, 0, 0.622);
    height: 100%;
    left: 0;
    position: absolute;
    top: 0;
    width: 100%;
    z-index: 1;
  }

  .container-records {
    align-items: center;
    display: flex;
    flex-direction: column;
    height: 100%;
    justify-content: center;
    z-index: 2;

    .box-records {
      align-items: center;
      display: flex;
      flex-direction: column;
    }

    img {
      width: 70%;
      max-width: 600px;

      @include breakpoint-for(tablet-large) {
        width: 100%;
      }
    }

    .description {
      color: white;
      font-family: $gotham_light;
      font-size: pxToRem(19px);
      text-align: justify;
      padding: 0 10px;

      @include breakpoint-for(tablet-large) {
        line-height: 40px;
        font-size: pxToRem(30px);
      }
    }

    .ButtonContainer {
      a > button {
        display: flex;
        margin-top: 40px;
        align-items: center;
        padding: 40px 30px;
        font-size: pxToRem(25px);
        box-sizing: border-box;

        @include breakpoint-for(tablet-large) {
          font-size: pxToRem(35px);
          width: max-content;
        }
      }
    }
  }
}
