@import "../../../assets/scss/variables";
@import "../../../assets/scss/mixins";
@import "../../../assets/scss/function";

.Courses {
  padding: 90px 0;

  .title-courses {
    width: 100%;
    display: flex;
    justify-content: flex-start;

    div {
      position: relative;

      p {
        margin: 0;
        position: absolute;
        top: -20px;
        left: 35px;
        font-size: pxToRem(45px);
        font-family: $bebasneue_normal;
      }

      img {
        position: relative;
        left: -100px;
      }
    }

    @include breakpoint-for(tablet) {
      div {
        img {
          position: unset;
        }

        p {
          margin: 0;
          position: absolute;
          top: -43px;
          right: 24px;
          left: unset;
          font-size: pxToRem(64px);
        }
      }
    }
  }

  .container-courses {
    display: flex;
    flex-direction: column;
    align-items: center;
    position: relative;

    .background-section {
      position: absolute;
      width: 100%;
      z-index: -1;
      top: 5%;
      height: 100vh;

      @include breakpoint-for(desktop-extra-large) {
        top: 0;
        height: auto;
      }

      @include breakpoint-for("4k") {
        display: none;
      }
    }

    .buttons-course {
      display: flex;
      margin-top: 50px;
      gap: 12px;
      flex-wrap: wrap;
      justify-content: center;

      @include breakpoint-for(tablet) {
      }

      button {
        cursor: pointer;
        height: 36px;
        display: flex;
        align-items: center;
        padding: 8px 13px 8px 13px;
        gap: 8px;
        justify-content: center;
        border: 1px solid rgba(0, 74, 72, 1);
        background-color: $white;
        color: rgba(0, 74, 72, 1);
        font-family: $gotham_light;
        font-size: pxToRem(16px);
        border-radius: 4px;

        &:hover {
          filter: brightness(0.8);
        }

        &.active {
          border: 1px solid $black;
          color: $white;
          background-color: $black;
          filter: brightness(1);

          svg {
            path {
              fill: white;
            }
          }
        }

        p {
          margin: 0;
        }
      }
    }

    .container-course {
      align-items: center;
      display: flex;
      flex-direction: column;
      justify-content: center;
      width: 100%;

      .distribution-course {
        display: flex;
        flex-wrap: wrap;
        gap: 10px;
        justify-content: center;
        margin-top: 40px;
        width: 100%;

        @include breakpoint-for(tablet) {
          gap: 20px;
          width: 80%;
        }
      }

      .course {
        align-items: center;
        background-color: rgba(51, 51, 51, 1);
        border-radius: 4px;
        background-size: cover;
        background-position: center;
        box-sizing: border-box;
        color: $white;
        display: flex;
        flex-direction: column;
        gap: 25px;
        height: 202px;
        justify-content: center;
        padding: 12px;
        cursor: pointer;
        width: 146px;

        &:hover {
          filter: brightness(0.8);
        }

        @include breakpoint-for(tablet) {
          height: 286px;
          padding: none;
          width: 286px;
        }

        img {
          height: 50px;
          width: 50%;

          @include breakpoint-for(tablet) {
            height: 100px;
            width: auto;
            max-width: 50%;
          }
        }

        div {
          background-color: rgba(0, 0, 0, 0.5);
          border-radius: 4px;
          padding: 10px;
        }

        span {
          font-family: $gotham_bold;
          font-size: pxToRem(15px);
          margin: 0;

          @include breakpoint-for(tablet) {
            font-size: pxToRem(20px);
          }
        }

        p {
          font-family: $gotham_normal;
          font-size: pxToRem(13px);
          margin: 0;

          @include breakpoint-for(tablet) {
            font-size: pxToRem(18px);
          }
        }
      }

      .description-container {
        width: 100%;
        margin-top: 120px;
        display: flex;
        justify-content: center;
        align-items: center;

        .description {
          display: flex;
          justify-content: space-between;
          flex-direction: column;
          align-items: center;
          gap: 40px;

          @include breakpoint-for(desktop-small) {
            align-items: center;
            flex-direction: row;
            display: flex;
          }

          @include breakpoint-for(desktop-large) {
            width: 80%;
            gap: 50px;
          }

          @include breakpoint-for(desktop-extra-large) {
            width: 60%;
          }

          .background {
            display: flex;
            align-items: center;
            width: 160px;
            min-height: 188px;
            position: relative;
            margin-right: 40px;

            div {
              strong {
                position: absolute;
                color: rgba(0, 74, 72, 1);
                font-size: pxToRem(48px);
                font-family: $bebasneue_light;
                right: 10px;
                top: 40px;

                &:last-child {
                  top: 85px;
                  right: -40px;
                }
              }
            }
          }

          .description-text {
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
            height: 100%;

            @include breakpoint-for(desktop-small) {
              align-items: flex-start;
            }

            span {
              color: $green_dark;
              font-family: $gotham_bold;
              font-size: pxToRem(20px);
              margin: 0 0 10px 0;
              width: max-content;
            }

            p {
              color: $black;
              font-family: $gotham_light;
              font-size: pxToRem(18px);
              max-width: 357px;
              text-align: left;
              margin: 0;

              @include breakpoint-for(desktop-small) {
                height: 89px;
              }
            }
          }
        }
      }

      .Button {
        margin-top: 116px;
        font-size: pxToRem(27px);
      }
    }
  }
}
