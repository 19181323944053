@import "../../../assets/scss/variables";
@import "../../../assets/scss/mixins";
@import "../../../assets/scss/function";

.Header {
  background-image: url("../../../assets/images/header/header.webp");
  background-position: 50% 100%;
  background-repeat: no-repeat;
  background-size: cover;
  height: 100vh;
  margin-top: 64px;
  min-height: 700px;

  @include breakpoint-for(mobile-medium) {
    height: 80vh;
  }

  @include breakpoint-for(tablet) {
    height: 85vh;
  }

  .bg {
    height: 100%;
    min-height: 700px;
    position: relative;
    display: flex;

    &::before {
      content: "";
      background: linear-gradient(
        91.34deg,
        rgba(0, 0, 0, 0.78) 13.24%,
        rgba(102, 102, 102, 0) 77.39%
      );
      height: 100%;
      left: 0;
      position: absolute;
      top: 0;
      width: 100%;
      z-index: 1;
    }

    .bg-desktop {
      height: 100%;
      position: relative;
      width: 100%;
      z-index: -1;
      display: none;

      @include breakpoint-for(tablet) {
        display: flex;
      }
    }

    .background-mobile {
      background-image: url("../../../assets/images/header/bg_mobile.webp");
      background-position: 10% 80%;
      background-repeat: no-repeat;
      background-size: cover;
      height: 100%;
      position: relative;
      width: 100%;
      z-index: -1;

      @include breakpoint-for(tablet) {
        display: none;
      }
    }

    .content {
      box-sizing: border-box;
      color: white;
      display: flex;
      flex-direction: column;
      height: 100%;
      justify-content: center;
      left: 0;
      position: absolute;
      text-align: left;
      padding: 0 20px;
      top: 0;
      width: 100%;
      z-index: 2;

      h1 {
        font-family: $bebasneue_light;
        font-size: pxToRem(56px);
        line-height: 73px;
        margin: 0;

        &.desktop {
          display: none;
        }
      }

      p {
        font-family: $gotham_light;
        font-size: pxToRem(16px);
        margin: 21px 0 0 0;
        max-width: 423px;
        line-height: 30px;
      }

      .ButtonContainer {
        margin-top: 108px;
        width: 100%;

        a {
          width: 100%;
        }
      }

      .Button {
        width: 100% !important;
        font-size: pxToRem(24px);
      }

      @include breakpoint-for(tablet) {
        padding: 0 90px;
        align-items: flex-start;

        h1 {
          font-size: pxToRem(86px);
          line-height: 98.88px;

          &.desktop {
            display: flex;
          }

          &.mobile {
            display: none;
          }
        }

        p {
          font-size: pxToRem(20px);
          margin: 12px 0 0 0;
        }

        .ButtonContainer {
          width: 315px;
        }
      }
    }
  }
}
