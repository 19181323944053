@import './fonts';

$architects_normal: "Architects Daughter";

$bebasneue_thin: "Bebas Neue Thin";
$bebasneue_normal: "Bebas Neue Normal";
$bebasneue_bold: "Bebas Neue Bold";
$bebasneue_light: "Bebas Neue Light";

$gotham_thin: "Gotham Thin";
$gotham_normal: "Gotham Normal";
$gotham_bold: "Gotham Bold";
$gotham_light: "Gotham Light";

$black: rgb(0, 0, 0);
$secondary_color: rgba(100, 204, 201, 1);
$white: #FFFFFF;
$green_dark: rgba(0, 74, 72, 1);
$grey: rgba(240, 240, 240, 1);

$breakpoints: (
  "mobile-medium": 400px,
  "mobile-larger": 576px,
  "tablet": 768px,
  "tablet-large": 992px,
  "desktop-small": 1024px,
  "desktop-medium": 1440px,
  "desktop-large": 1600px,
  "desktop-extra-large": 1800px,
  "4k": 2300px
);