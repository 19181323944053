@import "../../../assets/scss/variables";
@import "../../../assets/scss/mixins";
@import "../../../assets/scss/function";

.History {
  align-items: center;
  background-color: $black;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  padding: 67px 20px;

  @include breakpoint-for(tablet) {
    align-items: flex-start;
    padding: 67px 140px;
  }

  .title-history {
    color: $white;
    display: flex;
    align-items: center;

    p {
      font-family: $bebasneue_normal;
      font-size: pxToRem(48px);
      margin: 0 10px;
    }

    img {
      width: 54px;
      height: 38px;

      &:last-child {
        transform: rotate(180deg);
      }
    }
  }

  .containers-history {
    display: flex;
    justify-content: space-between;
    margin-top: 80px;

    .left-history {
      width: 100%;

      @include breakpoint-for(desktop-large) {
        width: 30%;
      }

      p {
        background-color: rgba(52, 52, 52, 1);
        border-radius: 4px;
        box-sizing: border-box;
        color: white;
        font-family: $gotham_light;
        font-size: pxToRem(16px);
        line-height: 25.09px;
        padding: 26px 24px;
        text-align: left;
      }

      .ButtonContainer {
        width: 100%;

        a {
          width: 100%;
        }
      }

      .Button {
        font-size: pxToRem(27px);
        margin-top: 65px;
        width: 100%;
      }
    }

    .right-history {
      width: 60%;
      overflow: hidden;
      max-height: 900px;
      display: none;

      @include breakpoint-for(desktop-large) {
        display: flex;
      }
    }
  }
}
