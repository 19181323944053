@import "../../../assets/scss/variables";
@import "../../../assets/scss/mixins";
@import "../../../assets/scss/function";

.ButtonContainer {
  align-items: flex-start;
  display: flex;
  flex-direction: column;

  a {
    text-decoration: none;
  }

  .Button {
    align-items: center;
    background-color: $secondary_color;
    color: black;
    cursor: pointer;
    justify-content: center;
    display: inline-flex;
    border-radius: 4px;
    width: 315px;
    border: none;
    font-family: $bebasneue_normal;
    font-size: pxToRem(15px);
    height: 47px;
    padding: 13px, 24px, 13px, 24px;
    transition: filter 0.2s;

    @include breakpoint-for(tablet) {
      font-size: pxToRem(24px);
    }

    &:hover {
      filter: brightness(0.9);
    }

    &.active {
      background-color: white;
      color: white;
    }
  }
}
