@import "../../../../assets/scss/variables";
@import "../../../../assets/scss/mixins";
@import "../../../../assets/scss/function";

.slick-arrow {
  display: none !important;
}

.BannerOne {
  box-sizing: border-box;
  background-repeat: no-repeat;
  background: url("../../../../assets/images/carrousel/bannerOne/Mobile.webp")
    center center no-repeat;
  background-size: 100% 100%;
  height: 640px;
  margin-top: 64px;
  position: relative;
  width: 100%;

  .form-box-desk {
    align-items: center;
    box-sizing: border-box;
    display: none;
    height: 100%;
    justify-content: flex-end;
    padding: 40px 10% 0 0;
    width: 100%;

    @include breakpoint-for(tablet) {
      display: flex;
    }

    .form-container {
      background-color: white;
      box-sizing: border-box;
      width: 400px;
    }

    .form {
      box-sizing: border-box;
      padding: 0 10%;

      h3 {
        font-family: $bebasneue_normal;
        font-size: pxToRem(30px);
        font-weight: 500;
        margin: 20px 0 0 0;
        text-transform: uppercase;
      }

      form {
        align-items: center;
        display: flex;
        flex-direction: column;
        gap: 10px;
        padding: 30px 0;

        .form-group {
          align-items: center;
          display: flex;
          flex-direction: column;
          width: 100%;

          label,
          input {
            font-size: 15px;
            font-family: $gotham_normal;
          }

          small {
            color: red;
            font-family: $gotham_normal;
            font-size: 15px;
            margin: 0;
            text-align: left;
            width: 100%;
          }

          label {
            color: black;
            width: 100%;
            text-align: left;
          }

          input {
            border: 2px solid #64ccc9;
            box-sizing: border-box;
            margin: 10px 0 0 0;
            outline: none;
            padding: 10px;
            width: 100%;
          }

          button {
            background: #64ccc9;
            border-radius: 4px;
            border: none;
            color: black;
            cursor: pointer;
            font-family: $gotham_normal;
            font-size: 15px;
            padding: 10px 20px;
          }

          button:hover {
            background: #43b6b3;
          }

          button:disabled {
            background: #91adad;
            cursor: auto;
          }
        }
      }
    }
  }

  @include breakpoint-for(tablet) {
    background-repeat: no-repeat;
    background: url("../../../../assets/images/carrousel/bannerOne/HD-matricula.webp")
      center center no-repeat;
    background-size: 100% 100%;
    height: 650px;
    margin-top: 64px;
    width: 100%;
  }

  @include breakpoint-for(desktop-small) {
    background-repeat: no-repeat;
    background: url("../../../../assets/images/carrousel/bannerOne/HD-matricula.webp")
      center center no-repeat;
    background-position: 10% 80%;
    background-size: cover;
    height: 900px;
    margin-top: 64px;
    width: 100%;
  }

  @include breakpoint-for(desktop-medium) {
    background: url("../../../../assets/images/carrousel/bannerOne/FHD.webp")
      center center no-repeat;
    background-position: 10% 100%;
    background-size: cover;
    height: 750px;
    margin-top: 64px;
    width: 100%;
  }

  @include breakpoint-for(desktop-extra-large) {
    background: url("../../../../assets/images/carrousel/bannerOne/FHD.webp")
      center center no-repeat;
    background-size: 100% 100%;
    height: 750px;
    margin-top: 64px;
    width: 100%;
  }

  @include breakpoint-for("4k") {
    background-repeat: no-repeat;
    background: url("../../../../assets/images/carrousel/bannerOne/4K.webp")
      center center no-repeat;
    background-size: 100% 100%;
    height: 750px;
    margin-top: 64px;
    width: 100%;
  }
}

.form-box-open {
  align-items: center;
  background-color: white;
  display: flex;
  flex-direction: column;
  padding-bottom: 30px;
  width: 100%;

  .form-container {
    width: 100%;
  }

  .form {
    padding: 0 10%;
    box-sizing: border-box;

    h3 {
      font-family: $bebasneue_normal;
      font-size: pxToRem(40px);
      font-weight: 500;
      margin: 30px 0 0 0;
      text-transform: uppercase;
    }

    form {
      align-items: center;
      display: flex;
      flex-direction: column;
      gap: 10px;
      padding: 30px 0;

      .form-group {
        align-items: center;
        display: flex;
        flex-direction: column;
        width: 100%;

        label,
        input {
          font-family: $gotham_normal;
          font-size: 15px;
        }

        label {
          color: black;
          text-align: left;
          width: 100%;
        }

        small {
          color: red;
          font-family: $gotham_normal;
          font-size: 15px;
          margin: 0;
          text-align: left;
          width: 100%;
        }

        input {
          border: 2px solid #64ccc9;
          box-sizing: border-box;
          margin: 10px 0 10px 0;
          outline: none;
          padding: 10px;
          width: 100%;
        }

        button {
          background: #64ccc9;
          border-radius: 4px;
          border: none;
          color: black;
          cursor: pointer;
          font-family: $gotham_normal;
          font-size: 15px;
          padding: 10px 20px;
        }

        button:hover {
          background: #43b6b3;
        }

        button:disabled {
          background: #91adad;
          cursor: auto;
        }
      }
    }
  }
}

@include breakpoint-for(tablet) {
  .form-box-open {
    display: none;
  }
}

.dropdown {
  position: relative;
  display: inline-block;
  // width: 200px;
  width: 100%;

  .dropdown-title {
    align-items: center;
    // background-color: #64ccc9;
    background-color: white;
    border-radius: 4px;
    border: 2px solid #64ccc9;
    color: black;
    cursor: pointer;
    display: flex;
    font-family: $gotham_normal;
    font-size: 16px;
    justify-content: space-between;
    padding: 10px 15px;
    transition: background-color 0.3s;

    .arrow {
      margin-left: 10px;
      font-size: 12px;
    }

    &:hover {
      background-color: #35bfbb;
    }
  }

  .dropdown-options {
    background-color: #ffffff;
    border-radius: 4px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    left: 0;
    list-style: none;
    margin: 0;
    padding: 5px 0;
    position: absolute;
    top: calc(100% + 5px);
    width: 100%;
    z-index: 10;

    li {
      color: #333333;
      cursor: pointer;
      font-family: $gotham_normal;
      font-size: 14px;
      padding: 10px 15px;
      transition: background-color 0.3s, color 0.3s;

      &:hover {
        background-color: #64ccc9;
        color: #ffffff;
      }
    }
  }
}
