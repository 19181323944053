@import "../../../assets/scss/variables";
@import "../../../assets/scss/mixins";
@import "../../../assets/scss/function";

.Teachers {
  align-items: center;
  background-color: $grey;
  display: flex;
  flex-direction: column;
  padding: 64px 30px;

  .title-teacher {
    align-items: center;
    display: flex;
    flex-direction: column;
    justify-content: center;

    img {
      height: 45px;
      width: 50px;
    }

    p {
      color: $black;
      font-family: $bebasneue_normal;
      font-size: pxToRem(40px);
      margin: 13px 0 0 0;

      @include breakpoint-for(tablet) {
        font-size: pxToRem(48px);
      }
    }
  }

  .container-teachers {
    display: flex;
    flex-wrap: wrap;
    gap: 67px;
    justify-content: center;
    margin-top: 100px;
    width: 100%;

    @include breakpoint-for(desktop-medium) {
      justify-content: space-evenly;
      max-width: 1100px;
      width: 80%;
    }

    .teacher {
      background-color: $white;
      border-radius: 5px;
      height: 270px;
      position: relative;
      width: 290px;

      div {
        margin-top: -15px;

        span {
          color: $black;
          font-family: $gotham_light;
          font-size: pxToRem(20px);

          strong {
            font-family: $gotham_bold;
          }
        }

        p {
          color: $black;
          font-family: $gotham_light;
          font-size: pxToRem(16px);
          margin: 5px 0 0 0;
        }

        .networks {
          display: flex;
          gap: 0 10px;
          justify-content: center;
          margin-top: 40px;

          button {
            align-items: center;
            background-color: transparent;
            border: transparent;
            border-bottom: 1px solid rgb(34, 224, 218);
            color: rgb(23, 141, 137);
            cursor: pointer;
            display: flex;
            font-family: $bebasneue_normal;
            font-size: 20px;
            justify-content: center;
            letter-spacing: 2px;

            img {
              border-radius: initial;
              height: 18px;
              position: initial;
              width: 18px;
            }
          }
        }
      }

      .image {
        background-position: 50% 10%;
        background-size: cover;
        border-radius: 50%;
        height: 182px;
        left: 19%;
        position: relative;
        top: -30px;
        width: 182px;
      }
    }
  }
}
